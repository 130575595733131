<div class="progress" style="position: relative" *ngIf="isLoading">
  <div class="progress-bar indeterminate"></div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white">{{ spinnerText }}</p>
</ngx-spinner>
<!-- <div class="position-fixed left-0 d-flex justify-content-center align-items-center w-100" style="bottom:10px">
  <app-toast-control aria-live="polite" aria-atomic="true"></app-toast-control>
</div> -->

<app-toast-control style="position: absolute; bottom: 5px; right: 5px" aria-live="polite"
  aria-atomic="true"></app-toast-control>

<router-outlet></router-outlet>