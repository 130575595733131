<div class="container-fluid simple-navbar d-flex justify-content-between align-items-center">
    <div class="logo">
        <img src="./assets/images/logo.jpg" style="width: 180px ;" alt="">
    </div>



    <div class="d-flex align-items-center justify-content-start ">
        <a *ngIf="isLoggedIn" [routerLink]="'/app'" class="btn btn-dark btn-sm me-3">GET STARTED</a>

        <span>
            <div ngbDropdown class="d-inline-block">
                <a type="button" *ngIf="isLoggedIn" id="user-circle" ngbDropdownToggle>
                    <fa-icon [icon]="faUserCircle" [size]="'2x'"></fa-icon>
                </a>
                <div ngbDropdownMenu aria-labelledby="user-circle">
                    <a ngbDropdownItem [routerLink]="['../auth/login']">LOGOUT</a>
                </div>
            </div>

            <span *ngIf="!isLoggedIn">
                <button class="btn btn-link" [routerLink]="'/auth/login'">LOGIN</button>
            </span>
        </span>
    </div>
</div>

<div class="document-details">
    <div ngbAccordion class="mt-3">
        <div ngbAccordionItem [collapsed]="false" *ngFor="let topic of document.topics">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>{{topic.title}}</button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div ngbAccordion>
                            <div ngbAccordionItem [collapsed]="false" *ngFor="let subtopic of topic.subtopics">
                                <h2 ngbAccordionHeader>
                                    <button ngbAccordionButton>{{subtopic.title}}</button>
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <ng-template>
                                            <div [innerHTML]="replace(subtopic.description)"></div>

                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>