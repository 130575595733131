import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownModule, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { ToastControlComponent } from './shared/controls/toast-control/toast-control.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeadersRequestInterceptor } from './shared/services/interceptors/headers-Interceptor/headers-interceptor.interceptor';
import { SharedOrganizationService } from './shared/services/shared/organization/shared-organization.service';
import { CustomModalService } from './shared/services/ngBModal/custom-modal.service';
import { GlobolErrorHandlerService } from './shared/services/error/globol-error-handler.service';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgbModule,
    NgxSpinnerModule,
    ToastControlComponent,
    FontAwesomeModule,
    NgbDropdownModule
  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: HeadersRequestInterceptor, multi: true, deps: [SharedOrganizationService] },

    { provide: NgbModal, useClass: CustomModalService },
    { provide: ErrorHandler, useClass: GlobolErrorHandlerService }

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
