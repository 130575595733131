<div style="height: 100%; overflow-y: auto;">
    <div class="container mt-5">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center mb-4">Privacy Policy for Vendors and their Consumers</h1>
                <p class="text-center mb-5">At <strong>clfx.in</strong> and <strong>clockflex.com</strong> ("we," "our,"
                    or "us"), operated by One N Quarter Technology PVT LTD, protecting your privacy is our priority.
                    This Privacy Policy outlines how we collect, use,
                    disclose, and safeguard your information when you use our platform to book appointments (for
                    customers) or operate as a vendor. By accessing or using our services, you agree to the terms of
                    this Privacy Policy.</p>

                <h2>1. Introduction</h2>
                <p>At <strong>clfx.in</strong> and <strong>clockflex.com</strong> ("we," "our," or "us"), operated by
                    One N Quarter Technology PVT LTD, protecting
                    your privacy is our priority. This Privacy Policy outlines how we collect, use, disclose, and
                    safeguard your information when you use our platform to book appointments (for customers) or operate
                    as a vendor. By accessing or using our services, you agree to the terms of this Privacy Policy.</p>

                <h2>2. Information We Collect</h2>
                <h3>2.1 Personal Information (for both Consumers and Vendors):</h3>
                <ul>
                    <li>Name, contact details (email address, phone number), and address.</li>
                    <li>Payment and banking details for processing transactions and payouts.</li>
                    <li>Government-issued identification and business registration documents (for vendors, if
                        applicable).</li>
                    <li>Booking history, preferences (for consumers), and login activity (for vendors).</li>
                </ul>
                <h3>2.2 Non-Personal Information:</h3>
                <ul>
                    <li>Device information, such as IP address, browser type, and operating system.</li>
                    <li>Usage data, including interactions with our website or mobile app (for both consumers and
                        vendors).</li>
                    <li>Cookies and tracking technologies.</li>
                </ul>

                <h2>3. How We Use Your Information</h2>
                <h3>3.1 To provide and manage our services, including:</h3>
                <ul>
                    <li>Facilitating appointment bookings with vendors (for consumers).</li>
                    <li>Facilitating vendor registration and account management (for vendors).</li>
                    <li>Sending booking confirmations, reminders, and communications (for consumers).</li>
                    <li>Processing payments, transactions, and payouts.</li>
                    <li>Communicating updates, notifications, and support.</li>
                </ul>

                <h3>3.2 To enhance user experience, including:</h3>
                <ul>
                    <li>Personalizing your interactions with our platform (for both consumers and vendors).</li>
                    <li>Providing support and responding to inquiries (for both consumers and vendors).</li>
                    <li>Analyzing usage trends and improving our services.</li>
                </ul>

                <h3>3.3 To comply with legal obligations and ensure security, including:</h3>
                <ul>
                    <li>Preventing fraud and unauthorized access.</li>
                    <li>Complying with applicable laws and regulations.</li>
                </ul>

                <h2>4. Information Sharing and Disclosure</h2>
                <h3>4.1 We do not sell or rent your personal information to third parties.</h3>
                <h3>4.2 We may share your information with:</h3>
                <ul>
                    <li><strong>For consumers</strong>: Vendors to facilitate appointment bookings.</li>
                    <li><strong>For vendors</strong>: Payment processors and financial institutions to facilitate
                        transactions.</li>
                    <li>Third-party service providers who assist with platform operations (e.g., hosting services, cloud
                        storage providers).</li>
                    <li>Regulatory authorities or legal entities when required by law.</li>
                </ul>

                <h2>5. Data Retention</h2>
                <p>We retain your information only as long as necessary to:</p>
                <ul>
                    <li>Fulfill the purposes outlined in this Privacy Policy.</li>
                    <li>Comply with legal and regulatory obligations.</li>
                    <li>Resolve disputes and enforce our Terms and Conditions.</li>
                </ul>

                <h2>6. Data Security</h2>
                <h3>6.1 We implement robust measures to protect your information, including:</h3>
                <ul>
                    <li>Encryption of sensitive data during transmission.</li>
                    <li>Regular monitoring and updates to security practices.</li>
                </ul>
                <h3>6.2 Both consumers and vendors are responsible for maintaining the confidentiality of their account
                    credentials.</h3>

                <h2>7. Consumers and Vendor Rights</h2>
                <h3>7.1 You have the right to:</h3>
                <ul>
                    <li>Access and review the personal information we hold about you.</li>
                    <li>Request corrections to inaccurate or incomplete information.</li>
                    <li>Request the deletion of your information, subject to legal or contractual obligations.</li>
                </ul>
                <h3>7.2 To exercise these rights, contact us at <a
                        href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a>.</h3>

                <h2>8. Cookies and Tracking Technologies</h2>
                <p>We use cookies and similar technologies to:</p>
                <ul>
                    <li>Enhance platform functionality and user experience.</li>
                    <li>Track usage patterns and improve services.</li>
                </ul>
                <p>You can manage your cookie preferences through your browser settings.</p>

                <h2>9. Changes to this Privacy Policy</h2>
                <p>We may update this Privacy Policy periodically. Any changes will be effective upon posting, and
                    significant updates will be communicated to you. Continued use of our platform signifies your
                    acceptance of the revised Privacy Policy.</p>

                <h2>10. Contact Information</h2>
                <p>For questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li>Email: <a href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a></li>
                    <li>Phone: +91 9924166945</li>
                    <li>Address: One N Quarter Technology PVT LTD,
                        602 Supan Serenity,
                        Near Kiran Motors,
                        Sola, Ahmedabad, 380060</li>
                </ul>

                <p class="mt-5 text-center"><strong>By using our platform, both consumers and vendors acknowledge that
                        they have read, understood, and agree to this Privacy Policy.</strong></p>
            </div>
        </div>
    </div>
</div>