export const environment = {
    production: false,
    apiUrl: 'https://scheduleddevapi.azurewebsites.net/api',
    blobUrl: 'https://schedulerappstoragedev.blob.core.windows.net/scheduler/organizations/',
    clientApp: 'learnedparrot.in',
    razorpayKey: "rzp_test_RNJi9f27uHsOE6",
    zoomAuthUrl: "https://zoom.us/oauth/authorize",
    zoomRedirectUrl: "http://localhost:4200/zoom"

};
