import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { isUserLoggedInGuard } from './shared/services/guards/is-user-logged-in/is-user-logged-in.guard';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutComponent } from './about/about.component';
import { ZoomSuccessComponent } from './modules/scheduler/modules/organizations/modules/organization/modules/business-settings/components/integrations/components/zoom-success/zoom-success.component';
import { DocumentationComponent } from './components/documentation/documentation.component';

export const routes: Routes = [
  {
    path: 'app',
    canActivate: [isUserLoggedInGuard],
    loadChildren: () =>
      import('./modules/scheduler/scheduler.module').then(
        (m) => m.SchedulerModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'zoom',
    component: ZoomSuccessComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'documentation',
    component: DocumentationComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error-pages',
    loadChildren: () =>
      import('./modules/error-pages/error-pages.module').then((m) => m.ErrorPagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
