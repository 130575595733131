<div class="container-fluid simple-navbar d-flex justify-content-between align-items-center">
    <div class="logo">
        <img src="./assets/images/logo.jpg" style="width: 180px; cursor: pointer;" [routerLink]="['../home']" alt="">
    </div>

    <div>

        <div class="d-flex align-items-center justify-content-start ">
            <a *ngIf="isLoggedIn" [routerLink]="'/app'" class="btn btn-dark btn-sm me-3">GET STARTED</a>

            <span>
                <div ngbDropdown class="d-inline-block">
                    <a type="button" *ngIf="isLoggedIn" id="user-circle" ngbDropdownToggle>
                        <fa-icon [icon]="faUserCircle" [size]="'2x'"></fa-icon>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="user-circle">
                        <a ngbDropdownItem [routerLink]="['../auth/login']">LOGOUT</a>
                    </div>
                </div>

                <span *ngIf="!isLoggedIn">
                    <button class="btn btn-link" [routerLink]="'/auth/login'">LOGIN</button>
                </span>
            </span>
        </div>
    </div>
</div>

<div class="about-details">
    <!-- Hero Section -->
    <section class="hero-section">
        <div class="container">
            <h1>Welcome to ClockFlex</h1>
            <p>The ultimate scheduling, booking, and planning solution for businesses and professionals.</p>
            <a *ngIf="isLoggedIn" [routerLink]="'/app'" class="btn btn-primary btn-lg">GET STARTED</a>
            <a *ngIf="!isLoggedIn" [routerLink]="'/auth/login'" class="btn btn-primary btn-lg">LOGIN</a>
        </div>
    </section>

    <!-- About Section -->
    <section class="features-section text-center">
        <div class="container">
            <h2>About ClockFlex</h2>
            <p>At ClockFlex, we understand that time is your most valuable resource. Whether you're a business
                owner, freelancer, or professional providing services, managing appointments and bookings can be
                time-consuming. That's where we come in! ClockFlex streamlines the entire process to help you focus
                on what really matters—growing your business and delivering excellent service.</p>
            <p>Our platform is designed to simplify the way you organize your calendar, schedule meetings, accept
                bookings, and plan events—all while ensuring a seamless experience for both you and your clients. With
                ClockFlex, you can easily manage appointments, plan events, and integrate payments to save time and
                reduce stress.</p>
        </div>
    </section>

    <!-- Features Section -->
    <section class="features-section text-center">
        <div class="container">
            <h2>Key Features</h2>
            <div class="row">
                <!-- Feature 1 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faCalendarCheck" [size]="'1x'"></fa-icon>

                        </div>
                        <h4>Effortless Scheduling</h4>
                        <p>Easily schedule meetings, appointments, and events with just a few clicks. Our user-friendly
                            interface ensures that you stay organized and avoid double-booking.</p>
                    </div>
                </div>
                <!-- Feature 2 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faUserCheck" [size]="'1x'"></fa-icon>

                        </div>
                        <h4>Professional Appointment Booking</h4>
                        <p>Allow your clients to book appointments with professionals directly. With ClockFlex,
                            clients can select their desired service, check availability, and secure their appointment
                            easily.</p>
                    </div>
                </div>
                <!-- Feature 3 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faCogs" [size]="'1x'"></fa-icon>

                        </div>
                        <h4>Customizable Booking Options</h4>
                        <p>Customize your services, set durations, and manage availability to match your business needs.
                            Give your clients flexibility while maintaining control over your schedule.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- More Features Section -->
    <section class="features-section text-center">
        <div class="container">
            <h2>More Features</h2>
            <div class="row">
                <!-- Feature 4 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faBell" [size]="'1x'"></fa-icon>
                        </div>
                        <h4>Automated Reminders & Notifications</h4>
                        <p>Never miss an appointment again. ClockFlex sends automated reminders and notifications
                            via email or SMS to keep both you and your clients informed and on track.</p>
                    </div>
                </div>
                <!-- Feature 5 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faCalendarAlt" [size]="'1x'"></fa-icon>

                        </div>
                        <h4>Calendar Integration</h4>
                        <p>Sync your ClockFlex schedule with Google Calendar, Outlook, or Apple Calendar to stay
                            up-to-date across all your devices.</p>
                    </div>
                </div>
                <!-- Feature 6 -->
                <div class="col-md-4 mb-4">
                    <div class="feature-box">
                        <div class="feature-icon">
                            <fa-icon [icon]="faCreditCard" [size]="'1x'"></fa-icon>

                        </div>
                        <h4>Payment Integration</h4>
                        <p>If you provide paid services, ClockFlex integrates with payment systems, allowing clients
                            to pay at the time of booking and simplifying your financial management.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Call-to-Action Section -->
    <section id="cta" class="cta-section">
        <div class="container">
            <h2>Ready to streamline your schedule?</h2>
            <p>Start using ClockFlex today and take control of your appointments, bookings, and events.</p>
            <a href="#" class="btn btn-light btn-lg" [routerLink]="['../auth/create-account']">Sign Up Now</a>
        </div>
    </section>

</div>