import { Component, OnInit } from '@angular/core';
import {
  faUserCircle,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import { SharedUserService } from '../shared/services/shared/user/shared-user.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  public faUserCircle: IconDefinition = faUserCircle;
  public faInstagram = faInstagram;
  public faXTwitter = faXTwitter;
  public faLinkedin = faLinkedin;
  public faFacebook = faFacebook;



  public isLoggedIn: boolean = false;
  constructor(private _sharedUserService: SharedUserService) { }

  public ngOnInit(): void {
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }
}
