<div style="height: 100%; overflow-y: auto;">

    <header class="bg-dark text-white py-3">
        <div class="container">
            <h1 class="h3">Terms and Conditions</h1>
            <p class="mb-0">ClockFlex Appointment and Resource Booking Platform</p>
        </div>
    </header>

    <main class="container my-5">
        <!-- Combined Terms and Conditions -->
        <section>
            <h2 class="h4">1. Acceptance of Terms</h2>
            <p>Welcome to ClockFlex.com ("we," "our," or "us"), operated by One 'N Quarter Technology PVT LTD. By
                accessing or using our platform as a vendor or consumer, you agree to comply with these Terms and
                Conditions. If you do not agree, you must refrain from using our services.</p>
        </section>

        <section>
            <h2 class="h4">2. Services Provided</h2>
            <p>We provide an online platform to schedule and manage appointments with service providers. We act solely
                as an intermediary and are not responsible for the services provided by third-party service providers or
                vendors.</p>
        </section>

        <!-- Vendor Eligibility and Responsibilities -->
        <section>
            <h2 class="h4">3. Vendor Eligibility and Responsibilities</h2>
            <h3 class="h5">3.1 Vendor Eligibility</h3>
            <ul>
                <li>Vendors must provide accurate and up-to-date business information during registration.</li>
                <li>Vendors are responsible for ensuring compliance with all applicable laws and regulations.</li>
            </ul>

            <h3 class="h5">3.2 Vendor Responsibilities</h3>
            <h4 class="h6">Appointment Management</h4>
            <ul>
                <li>Vendors must accurately update their availability for bookings.</li>
                <li>Vendors are responsible for honoring confirmed appointments.</li>
                <li>Cancellation and rescheduling policies must be clearly defined and communicated to consumers.</li>
            </ul>
            <h4 class="h6">Service Quality</h4>
            <ul>
                <li>Vendors must ensure that the services provided meet the quality standards described in their
                    listings.</li>
                <li>Any disputes or consumer grievances must be addressed promptly.</li>
            </ul>
            <h4 class="h6">Pricing and Fees</h4>
            <ul>
                <li>Vendors must clearly state their pricing, including any applicable taxes or additional charges.</li>
                <li>Vendors agree to pay a convenience fee of 3.5% on transactions made through the platform.</li>
            </ul>
        </section>

        <!-- Consumer Terms -->
        <section>
            <h2 class="h4">4. Consumer Responsibilities</h2>
            <h3 class="h5">4.1 User Eligibility</h3>
            <p>You must be at least 18 years old or have parental/guardian consent to use our platform. By using the
                platform, you confirm that all information provided by you is accurate and current.</p>

            <h3 class="h5">4.2 Account Registration</h3>
            <ul>
                <li>To book an appointment, you may need to create an account. You are responsible for maintaining the
                    confidentiality of your account details.</li>
                <li>You agree to notify us immediately of any unauthorized access to your account.</li>
            </ul>

            <h3 class="h5">4.3 Booking</h3>
            <ul>
                <li>Appointments can be scheduled based on the availability of service providers. We do not guarantee
                    the availability of any specific time slots.</li>
                <li>You are responsible for ensuring the accuracy of the details entered during the booking process.
                </li>
                <li>Bookings are considered confirmed only after you receive a confirmation email or notification.</li>
            </ul>
        </section>

        <!-- Payments and Fees -->
        <section>
            <h2 class="h4">5. Payments and Fees</h2>
            <ul>
                <li>Payments (if applicable) must be made in Indian Rupees (₹) in accordance with the terms outlined
                    during the booking process.</li>
                <li>A convenience fee of 3.5% will be applied to all transactions made through our platform.</li>
                <li>For refunds or transfers, a transfer fee of 3.5% will be deducted from the refunded or transferred
                    amount.</li>
                <li>Refunds for cancellations, if applicable, will be processed according to the vendor’s refund policy.
                </li>
                <li>We are not responsible for disputes regarding payments made directly to vendors or service
                    providers.</li>
            </ul>
        </section>

        <!-- General Terms -->
        <section>
            <h2 class="h4">6. Cancellation and Rescheduling</h2>
            <ul>
                <li>Cancellations must be made in accordance with the settings and policies defined by the respective
                    vendor or service provider.</li>
                <li>Rescheduling requests must adhere to the policies of the respective vendor or service provider.</li>
                <li>Refunds for cancellations, if applicable, will be credited to your original payment method within
                    5-7 business days.</li>
            </ul>
        </section>

        <section>
            <h2 class="h4">7. Data Privacy</h2>
            <ul>
                <li>Vendors and consumers must handle personal information responsibly and in compliance with applicable
                    data protection laws.</li>
                <li>The platform’s Privacy Policy governs the collection and use of information provided by vendors and
                    consumers.</li>
            </ul>
        </section>

        <section>
            <h2 class="h4">8. Dispute Resolution</h2>
            <ul>
                <li>We act as an intermediary and will assist in resolving disputes between vendors and consumers where
                    applicable.</li>
                <li>The platform reserves the right to mediate disputes and take appropriate actions, including
                    withholding payments or suspending accounts.</li>
            </ul>
        </section>

        <section>
            <h2 class="h4">9. Governing Law</h2>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of Indian
                Jurisdiction.</p>
        </section>

        <section>
            <h2 class="h4">10. Contact Information</h2>
            <p>For questions or concerns about these Terms and Conditions, please contact us at:</p>
            <ul>
                <li>Email: <a href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a></li>
                <li>Phone: +91 9924166945</li>
                <li>Address: One 'N Quarter Technology PVT LTD, 602 Supan Serenity, Near Kiran Motors, Sola, Ahmedabad,
                    380060</li>
            </ul>
        </section>

        <footer class="text-center mt-5">
            <p>By using this platform as a vendor or consumer, you acknowledge that you have read, understood, and agree
                to these Terms and Conditions.</p>
        </footer>
    </main>
</div>