import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SharedUserService } from '../../shared/services/shared/user/shared-user.service';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Texts } from '../../shared/constants/app-constants';

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule, NgbDropdownModule, NgbAccordionModule],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent {
  public document: IDocument = DocumentData;
  public isLoggedIn: boolean = false;
  public faUserCircle: IconDefinition = faUserCircle;

  constructor(private _sharedUserService: SharedUserService) { }

  public ngOnInit(): void {
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }

  public replace(text: string): string {
    return text.replace(/\n/g, '<br>');
  }
}


interface IDocument {
  topics: ITopic[];
}

interface ITopic {
  id: number;
  title: string;
  subtopics: ISubtopic[];
  description: string;
}

interface ISubtopic {
  id: number;
  title: string;
  description: string;
}

export const DocumentData: IDocument = {

  topics: [
    {
      id: 1,
      title: "Online Workspaces",
      subtopics: [
        {
          id: 1,
          title: "Zoom Workplace",
          description: `
How to Connect a Zoom Account to ClockFlex

Requirements:
- A Zoom account is required.
- You must have Administrator permissions in ClockFlex.

Key Rules:
1. ClockFlex can only connect to one Zoom account at a time.
2. Each Zoom account can only connect to one ClockFlex instance.

Steps to Connect a Zoom Account:
1. Navigate to the Integrations panel in ClockFlex.
2. Locate Zoom and click Set up.
3. Click Connect to Zoom.
4. Log into Zoom using your credentials or via SSO, Google, or Facebook.
5. Click Pre-approve, then click Authorize.
6. Select the appointment types you want Zoom to work with.
7. Customize your settings as needed.
8. Click Save.

Important Note:
- Default Behavior: New appointment types will have video conferencing disabled.
- To enable video conferencing for a new appointment type:
  1. Go to the Integrations panel.
  2. Locate Zoom and click Edit.
`
        }
      ],
      description: "sd"
    }
  ]


};
