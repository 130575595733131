import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell, faCalendarAlt, faCalendarCheck, faCogs, faCreditCard, faUserCheck, faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SharedUserService } from '../shared/services/shared/user/shared-user.service';
import { CommonModule } from '@angular/common';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [RouterModule, FontAwesomeModule, CommonModule, NgbDropdownModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent implements OnInit {
  public faBell: IconDefinition = faBell;
  public faCalendarAlt: IconDefinition = faCalendarAlt;
  public faCalendarCheck: IconDefinition = faCalendarCheck;
  public faUserCheck: IconDefinition = faUserCheck;
  public faCogs: IconDefinition = faCogs;
  public faCreditCard: IconDefinition = faCreditCard;
  public faUserCircle: IconDefinition = faUserCircle;

  public isLoggedIn: boolean = false;

  constructor(private _sharedUserService: SharedUserService) { }

  public ngOnInit(): void {
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }
}
