import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ToastService } from '../shared/toast/toast.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export abstract class TransactionalService {
  private _api = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(
    protected httpClient: HttpClient,
    private _toastService: ToastService,
    private _router: Router
  ) { }

  getList<T>(page: number, count: number): Observable<T[]> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('count', count.toString());

    return this.httpClient
      .get<T[]>(`${this._api}?${params.toString()}`)
      .pipe(retry(2), catchError(this.handleError.bind(this)));
  }

  get<T>(endpoint: string): Observable<T> {
    return this.httpClient
      .get<T>(`${this._api}/${endpoint}`)
      .pipe(retry(2), catchError(this.handleError.bind(this)));
  }

  add<T, Tres>(resource: T, endpoint: string): Observable<Tres> {
    return this.httpClient
      .post<Tres>(
        `${this._api}/${endpoint}`,
        JSON.stringify(resource),
        this.httpOptions
      )
      .pipe(retry(2), catchError(this.handleError.bind(this)));
  }

  delete(id: string | number): Observable<any> {
    return this.httpClient
      .delete(`${this._api}/${id}`)
      .pipe(retry(2), catchError(this.handleError.bind(this)));
  }

  update<T>(resource: T) {
    return this.httpClient
      .put(`${this._api}`, resource)
      .pipe(retry(2), catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.

    if (error.status === 409) {
      this._toastService.showError('Seems some of the data has been modified by other user/system. To persist your changes, reload the page.');
    }
    if (error.status === 401) {
      this._router.navigate(['auth/login']);
    }
    else {
      this._toastService.showError('Something bad happened; please try again later.');

    }

    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
